@use "./colors" as *;
@use "./variables" as *;
@use "./mixins" as *;
@use "./typography" as *;
@import "./page";
@import "./scroll";
@import "./components";
@import "./data-grid";
@import "./dashboard";
@import url('https://fonts.cdnfonts.com/css/sf-pro-display?styles=98774,98773,98770&display=swap');

@import "./kendo/kendo";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SF Pro Display', sans-serif;
  letter-spacing: 1px;
}


:root {
  @each $key, $value in $customColors {
    --#{"" + $key}: #{$value};
  }
}


html,
body {
  background-color: var(--backgroud);
  width: $app-width;
  min-width: $app-width;
  max-width: $app-width;
  height: $app-height;
  min-height: $app-height;
  max-height: $app-height;
  overflow: hidden;
  color: var(--surfaceHighEmphasis);

  @include app-typography();

  &#loading {
    background-color: var(--primary);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loading-content {
  display: none;
}

#loading {
  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    z-index: 1;

    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 26px;
      margin-bottom: 64px;

      .first-logo,
      .second-logo {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 58px;
        width: 123px;
      }

      .first-logo {
        background-image: url("/assets/img/logo-opt.svg");
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background: #FFFFFF;
      margin-bottom: 16px;
    }

    .text {
      color: #FFF;
      font-family: SF Pro Display;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

[class^="ico-"],
[class*="ico-"] {
  color: var(--icon);

  &.active {
    color: var(--iconActive);
  }

  &.button-icon {
    cursor: pointer;

    &:hover {
      color: var(--iconHover);

      &.active {
        color: var(--iconActiveHover);
      }
    }

  }
}

.circle-icon-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--yellow);
  color: var(--surface);

  &:hover {
    box-shadow: $hover-box-shadow;
  }

  [class^="ico-"],
  [class*="ico-"] {
    color: var(--surface);
  }
}

.pointer {
  cursor: pointer;
}

.mt-a {
  margin-top: auto;
}

.mb-a {
  margin-bottom: auto;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.simulation-creator-dialog {
  .k-dialog-title {
    font-size: 1.5 * $app-base-font-size;
    line-height: 2 * $app-base-font-size;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  .k-dialog-content {
    overflow: hidden;
  }

  .k-dialog {
    @include lt-lg {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.h-lt-xl {
  @include lt-xl {
    display: none !important;
  }
}

.h-gt-xl {
  @include gt-xl {
    display: none !important;
  }
}

.ellipsis {
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.break-word {
  overflow-wrap: anywhere;
}

.control-link {
  cursor: pointer;
  text-decoration: underline;
}