@use "./colors" as *;

.k-grid {
  border-width: 0px;

  .k-grid-header {
    border-width: 0px;

    .k-grid-aria-root {
      border-width: 0px;
    }

    .k-grid-header-wrap {
      border-width: 0px;
    }
  }

  .k-table-thead {
    background-color: var(--gray-20);
    border: 10px;
    margin-bottom: 0.5rem;

    th {
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: var(--fontSecondary);

      &:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-width: 0px;
      }

      &:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-width: 0px;
      }
    }
  }

  .k-table-row {
    &.k-selected,
    &.selected,
    &:hover {
      background-color: var(--activeBg) !important;
    }

    &.k-selected,
    &.selected {
      td {

        &:first-child {
          position: relative;

          &:before {
            content: '';
            width: 2px;
            height: 100%;
            display: block;
            background-color: var(--active);
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  .k-table-td {
    border-width: 0px;
    height: 2.5rem;

  }

  .k-grid-content {
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 2.5rem;
  }
}

kendo-grid-filter-menu-container {
  kendo-multicolumncombobox,
  kendo-textarea,
  .k-input,
  .k-dropdownlist,
  .k-combobox {
    min-height: 2rem;
    height: 2rem;

    &.k-focus {
      border-width: 0;
    }
  }

  .k-actions {
    button {
      font-size: 0.75rem;
      font-weight: 500;
      font-family: 'SF Pro Display', sans-serif;
      letter-spacing: 1px;
    }
  }
}

.k-pager-numbers {
  .k-button-flat-primary {
    .k-button-text {
      color: var(--fontSecondary);
    }
    &.k-selected {
      .k-button-text {
        color: var(--font);
      }
    }
  }
}
