@use "./colors" as *;
@use "./variables" as *;

.dashboard-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 85px 1fr;
  grid-gap: $app-base-padding;
  height: 100%;
  width: 100%;
  padding: $app-base-padding;

  .summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .summary-header-title {
      display: flex;
      align-items: center;
    }

    .summary-header-blocks {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .summary-content {
    overflow-y: auto;

    .charts-section {
      display: grid;
      grid-template-columns: 1fr 4fr;
      grid-template-rows: 1fr;
      grid-gap: $app-base-padding;
      padding-right: $app-base-padding;

      .charts-col {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding: 0.5 * $app-base-padding 0;
        .section-box {
          flex: 0 1 auto;
          transition: all 0.2s ease-in;

          &.hidden {
            flex: 0 1 40px;
          }
        }
      }

    }
  }

  .section-box {
    &.dark,
    &.blue {
      color: var(--primaryHighEmphasis);
      .section-title,
      .icon {
        color: var(--primaryHighEmphasis);
        font-weight: 400;
      }
    }
    &.dark {
      background-color: var(--surfaceHighEmphasis);
    }
    &.blue {
      background-color: var(--primary);
    }
  }

  .asset-name {
    color: var(--orange);
    padding: 0.25 * $app-base-padding;
    cursor: pointer;
  }

  .collapsable-area {
    transition: all 0.2s ease-in;
    &.hidden {
      height: 0;
      overflow: hidden;
    }
  }

  $toggle-list-size: 20px;
  .toggle-list {
    position: absolute;
    right: $toggle-list-size;
    top: -8px;
    width: $toggle-list-size;
    height: $toggle-list-size;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--yellow);
    cursor: pointer;

    &.hidden {
      transition-delay: 0.5s;
      .ico-arrow-down-1 {
        transform: rotate(0deg);
      }
    }

    .ico-arrow-down-1 {
      font-size: 5px;
      font-weight: 700;
      color: var(--primaryHighEmphasis);
      transform: rotate(180deg);
      transition: all 0.2s ease-in;
    }
  }
}
