@use "./variables" as *;
@use "./mixins" as *;

.k-tabstrip-content:focus,
.k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
  outline-width: 0 !important;
}

@include lt-xl {
  .k-tabstrip-items {
    li {
      .k-link {
        padding: 0.5 * $app-base-padding 0.75 * $app-base-padding;
        font-size: 0.75 * $app-base-font-size;
      }
    }
  }
}
.k-tabstrip-content, .k-tabstrip > .k-content {
  padding: 0 0 0 0;
}
