@use './variables' as *;
/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }
  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin gradient($color-1, $color-2, $color-3) {
  background: $color-2; /* Old browsers */
  background: -moz-radial-gradient(bottom, ellipse cover, $color-1 0%, $color-2 45%, $color-3 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(bottom, ellipse cover, $color-1 0%, $color-2 45%, $color-3 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at bottom, $color-1 0%, $color-2 45%, $color-3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-1', endColorstr='$color-3', GradientType=1); /* IE6-9 fallback on horizontal gradient */
}


@mixin animation($values) {
  -webkit-animation: $values;
  -moz-animation: $values;
  -o-animation: $values;
  animation: $values;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin border-radius($values) {
  -webkit-border-radius: $values;
  -moz-border-radius: $values;
  border-radius: $values;
}

@mixin box-shadow($values...) {
  -webkit-box-shadow: $values;
  -moz-box-shadow: $values;
  box-shadow: $values;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    //off for IE - performance
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ccc;
  }
}

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin text-shadow($values...) {
  text-shadow: $values;
  -webkit-filter: drop-shadow($values);
  -ms-filter: drop-shadow($values);
  -moz-filter: drop-shadow($values);
}

@mixin transform($values) {
  -webkit-transform: $values;
  -ms-transform: $values;
  -moz-transform: $values;
  -o-transform: $values;
  transform: $values;
}

@mixin transition($values...) {
  -webkit-transition: $values;
  -moz-transition: $values;
  -o-transition: $values;
  transition: $values;
}

@mixin touch-scroll {
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}

@mixin user-select($value: none) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin icon($value) {
  font-family: icons;
  content: $value;
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@mixin xs {
  @media screen and (max-width: $resXS - 1) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: $resXS) and (max-width: $resSM -1) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: $resSM) and (max-width: $resMD - 1) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: $resMD) and (max-width: $resLG - 1) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: $resLG) and (max-width: $resXL - 1) {
    @content;
  }
}

@mixin xxl {
  @media screen and (min-width: $resXXL) {
    @content;
  }
}


@mixin lt-sm {
  @media screen and (max-width: $resSM - 1) {
    @content;
  }
}
@mixin lt-md {
  @media screen and (max-width: $resMD - 1) {
    @content;
  }
}
@mixin lt-lg {
  @media screen and (max-width: $resLG - 1) {
    @content;
  }
}
@mixin lt-xl {
  @media screen and (max-width: $resXL - 1) {
    @content;
  }
}
@mixin lt-xxl {
  @media screen and (max-width: $resXXL - 1) {
    @content;
  }
}

@mixin gt-xs {
  @media screen and (min-width: $resXS) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: $resSM) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: $resMD) {
    @content;
  }
}
@mixin gt-lg {
  @media screen and (min-width: $resLG) {
    @content;
  }
}
@mixin gt-xl {
  @media screen and (min-width: $resXL) {
    @content;
  }
}


@mixin internetExplorer {
  //ie 10, 11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin safari {
  @media not all and (min-resolution: .001dpcm) {
    @content;
  }
}

@mixin firefox {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
