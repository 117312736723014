$resXS: 600px !default;
$resSM: 800px !default;
$resMD: 1024px !default;
$resLG: 1440px !default;
$resXL: 1920px !default;
$resXXL: 2560px !default;

$app-height: 100vh;
$app-width: 100vw;

$app-base-font-size: 16px;
$app-base-padding: 16px;
$app-base-border-radius: 16px;

$app-base-field-gap: 16px;
$app-base-small-gap: 8px;
$app-base-normal-gap: 16px;
$app-base-large-gap: 24px;
$app-base-xlarge-gap: 32px;

$side-menu-width: 80px;
$side-menu-full-width: 350px;
$side-menu-padding: 4px;
$side-menu-logo-size: 60px;
$side-menu-alert-size: 30px;

$filtered-list-width: 450px;
$filtered-list-width-sm: 400px;

$aside-section-width: 300px;
$toggle-aside-size: 20px;

$box-shadow: 1px 1px 10px 0px rgb(4, 26, 55, 0.2);
$hover-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.4) !important;
