kendo-pdf-export {
  font-family: "Poppins", sans-serif;
  font-size: 12px;

}
.k-pdf-export {
  .small-input {
    background-color: transparent !important;
    border-width: 0px !important;
    padding: 0px !important;
    input {
      background-color: transparent !important;
    }
  }
  .editable-control {
    background-color: transparent !important;
    border-width: 0px !important;
    padding: 0px !important;
    max-height: unset;
    &.percent {
      height: 22px;
      line-height: 22px;
    }

    .k-icon-button {
      display: none;
    }
    .k-input-value-text {
      text-align: right;
      text-overflow: unset;
      white-space: normal;
    }
  }
  .ico-add-square-1 {
    display: none;
  }

}
