@use "./variables" as *;
@use "./mixins" as *;

@include lt-xl {
  .k-upload {

    .k-upload-dropzone {
      padding: 0.25 * $app-base-padding;

      .k-upload-button {
        padding: 0.25 * $app-base-padding 0.5 * $app-base-padding;
        font-size: 0.75 * $app-base-font-size;
      }

      .k-dropzone-hint {
        font-size: 0.75 * $app-base-font-size;
      }
    }
  }
}
