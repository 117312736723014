@use  "../colors" as *;

$kendo-color-white: map-get($customColors, surface);
$dark-primary-text: map-get($customColors, font) !default;
$light-primary-text: $kendo-color-white !default;
$custom-palettes: (

  // primary
  primary: (
    50 : #e6e2ea,
    100 : #bfb6cb,
    200 : #9585a8,
    300 : #6b5485,
    400 : #4b306a,
    500 : #2b0b50,
    600 : #260a49,
    700 : #200840,
    800 : #1a0637,
    900 : #100327,
    A100 : #8e62ff,
    A200 : #692fff,
    A400 : #4600fb,
    A700 : #3f00e1,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $dark-primary-text,
      600: $dark-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $dark-primary-text,
      A400: $dark-primary-text,
      A700: $light-primary-text
    )
  ),
  // secondary
  secondary: (
    50 : #f0ecf4,
    100 : #d8cee4,
    200 : #bfaed2,
    300 : #a58ebf,
    400 : #9175b2,
    500 : #7e5da4,
    600 : #76559c,
    700 : #6b4b92,
    800 : #614189,
    900 : #4e3078,
    A100 : #dac2ff,
    A200 : #bc8fff,
    A400 : #9e5cff,
    A700 : #8e42ff,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $light-primary-text,
      400: $light-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $light-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text
    )
  )
);

$primary-palette-name: map-get($custom-palettes, primary);
$secondary-palette-name: map-get($custom-palettes, secondary);


/// The color for informational messages and states.
/// @group color-system
/// @type Color
$kendo-color-info: map-get($customColors, info) !default;

/// The color for success messages and states.
/// @group color-system
/// @type Color
$kendo-color-success: map-get($customColors, success) !default;

/// The color for warning messages and states.
/// @group color-system
/// @type Color
$kendo-color-warning: map-get($customColors, warning) !default;

/// The color for error messages and states.
/// @group color-system
/// @type Color
$kendo-color-error: map-get($customColors, error) !default;

@import "@progress/kendo-theme-material/dist/all.scss";
@import "./inputs";
@import "./buttons";
@import "./labels";
@import "./grid";
@import "./pdf";
@import "./tabstrip";
@import "./upload";
@import "./avatar";
@import "./panelbar";

.k-notification-group  {
  z-index: 10002;
}

.k-px-0 {
  padding-inline: 0;
}