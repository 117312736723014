@use "./colors" as *;
@use "./variables" as *;

// CUSTOM TAB STYLE [START]
.app-tabstrip {
  display: flex;
  flex-direction: column;

  &.column {
    flex-direction: row;
    .app-tabstrip-tabs {
      flex-direction: column;
      border-bottom: none;
      border-right: 1px solid var(--surfaceOutline);
    }
  }

  .app-tabstrip-tabs {
    display: flex;
    border-bottom: 1px solid var(--surfaceOutline);
    margin-right: 2* $app-base-padding;

    .app-tabstrip-tab {
      margin: 0 2 * $app-base-padding;
      padding: $app-base-padding 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 0.8rem;
      font-weight: 600;

      &.active {
        border-bottom: 3px solid var(--primary);
      }
      &:hover {
        border-bottom: 3px solid var(--primaryVariant);
      }

    }
  }

  .app-tabstrip-content {
    display: flex;
  }
}
// CUSTOM TAB STYLE [END]

// CUSTOM INPUT [START]
.small-input {
  width: 100%;
  height: 24px;
  border: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  background-color: var(--customBackground);
  border-radius: 5px;
  text-align: end;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}
// CUSTOM INPUT [END]
