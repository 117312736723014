@use "./colors" as *;
@use "./variables" as *;

.k-button {
  align-items: center;
  box-shadow: none;

  &.k-disabled {
    background-color: var(--gray-20) !important;
    color: var(--surface);
  }

  &:hover {
    box-shadow: $hover-box-shadow;
  }

  &.k-button-sm {
    padding: 0.25 * $app-base-padding $app-base-padding;
    font-size: 0.75 * $app-base-font-size;
    height: 2rem;
  }

  &.k-button-solid-primary {
    background-color: var(--active);
    border-width: 0;
  }

  &.k-button-solid-secondary {
    color: var(--primaryHighEmphasis);
  }

  &.k-button-solid-light {
    background-color: var(--light);
  }

  &[themecolor='primary'],
  &[themecolor='secondary'],
  &[themecolor='light'],
  &[themecolor='info'],
  &[themecolor='success'],
  &[themecolor='error'],
  &[themecolor='warning'] {
    .k-button-text {
      color: var(--primaryHighEmphasis);
    }
  }

  .k-button-text {
    line-height: normal;
  }
}

.k-button-icon {
  color: var(--icon);
}
