@use "./colors" as *;
@use "./variables" as *;

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border: none;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

::-webkit-scrollbar-thumb:active {
  background: var(--primary);
}

::-webkit-scrollbar-track {
  background: var(--surfaceOutline);
  border: none;
  border-top-right-radius: $app-base-border-radius;
  border-bottom-right-radius: $app-base-border-radius;
}

::-webkit-scrollbar-track:hover {
  background: var(--surfaceOutline);
}

::-webkit-scrollbar-track:active {
  background: var(--surfaceOutline);
}

::-webkit-scrollbar-corner {
  background: transparent;
}
