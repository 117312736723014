@use "./colors" as *;
@use "./variables" as *;
@use "./mixins" as *;

.page-container {
  display: grid;
  grid-template-columns: auto $aside-section-width;
  grid-gap: $app-base-padding;
  height: 100%;
  width: 100%;
  //position: relative;
  @include transition(all 0.5s ease-in);

  &.with-list {
    display: grid;
    grid-template-columns: $filtered-list-width auto $aside-section-width;
    grid-gap: $app-base-padding;

    &.hidden-aside {
      grid-template-columns: $filtered-list-width auto 0px;

      aside-section {
        width: 0px;
      }
    }
  }

  &.hidden-list {
    grid-template-columns: 0px auto $aside-section-width;

    app-filtered-list {
      width: 0px;
    }
  }

  &.hidden-aside {
    grid-template-columns: auto;

    aside-section {
      width: 0px;
    }

    .page-content {
      border-right-width: 0;
    }
  }

  &.hidden-list.hidden-aside {
    grid-template-columns: 0px auto 0px;
  }

  .page-content {
    transition: all 0.5s ease-in;
    padding: calc(1.5 * $app-base-padding);
    overflow-y: auto;
    max-height: $app-height;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--gray-20);
    position: relative;

    &:has(.page-header.sticky) {
      padding-top: 0;
    }

    .page-header {
      display: flex;
      align-items: center;
      gap: 1rem;

      &.sticky {
        position: sticky;
        top: 0px;
        padding-top: calc(1.5 * $app-base-padding);
        background-color: var(--surface);
        z-index: 2;
      }
    }

    .page-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      gap: 1rem;


    }

    &.dictionary-page {
      height: 100%;
      overflow: hidden;
      max-height: 100%;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 80px 1fr 1fr;
      grid-gap: $app-base-padding;

      .dictionary-box {
        overflow: hidden;
      }
    }
  }

  .header-grid {
    padding-top: $app-base-padding;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $app-base-padding;

    &.sticky {
      position: sticky;
      top: 0px;
      background-color: var(--backgroud);
      z-index: 10;
    }
  }

  .aside-section {
    @include transition(all 0.5s ease-in);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    padding-left: 0;

    .aside-section-content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }

    .toggle-aside {
      position: absolute;
      right: calc(#{$aside-section-width} - #{$app-base-padding} + #{$toggle-aside-size * 0.25});
      top: 14px;
      width: $toggle-aside-size;
      height: $toggle-aside-size;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--yellow);
      cursor: pointer;
      transition-delay: 0.5s;
      z-index: 1;

      &.hidden {
        right: 0px;

        transition-delay: 0s;

        .ico-arrow-down-1 {
          transform: rotate(90deg);
          margin-left: -2px;
        }
      }

      .ico-arrow-down-1 {
        margin-left: 2px;
        font-size: 5px;
        font-weight: 700;
        color: var(--primaryHighEmphasis);
        transform: rotate(-90deg);
        transition: all 0.2s ease-in;
      }
    }

    .context-selector {
      flex: 0;
    }


    .portfolio {
      color: var(--surfaceMediumEmphasis);
      font-size: 12px;
      font-weight: 600;
      height: 40px;
    }

    .dictionaries-section {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      height: calc(100% - 102px);
      justify-content: space-evenly;

      app-saving-profile,
      app-financial-assumptions {
        flex: 1;
        max-height: 50%;
      }
    }
  }

  .floating-buttons {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    display: flex;
    gap: 0.5rem;

    [class^="ico-"],
    [class*=" ico-"] {
      color: var(--surface);
      font-size: 24px;
    }
  }
}

.action-icon {
  font-size: 24px;
  display: flex;
  align-items: center;
  //flex: 1;
  width: 24px;


  &.sm {
    font-size: 9px;
    width: 9px;
  }

  &.md {
    font-size: 17px;
    width: 17px;
  }

  &.lg {
    font-size: 32px;
    width: 32px;
  }

  & > * {
    cursor: pointer;
  }


  &.disabled {
    [class^="ico-"],
    [class*=" ico-"] {
      color: var(--primaryDisabled);
    }
  }

  &.circle {
    width: 24px;
    height: 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--yellow);

    &.lg {
      font-size: 22px;
      width: 40px;
      height: 40px;
    }

    [class^="ico-"],
    [class*=" ico-"] {
      color: var(--surface) !important;
    }
  }

  .ico-add-circle {
    color: var(--yellow) !important;
  }

  .ico-remove-circle {
    color: var(--red) !important;
  }

  .ico-save,
  .ico-link {
    color: var(--primary) !important;
  }

  [class^="ico-"],
  [class*=" ico-"] {
    &:hover:before {
      opacity: 0.8;
    }
  }
}

.section-box {
  padding: $app-base-padding;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background-color: transparent;

  &.scroll {
    overflow-y: auto;
  }

  &.secondary {
    background-color: var(--secondaryVariant);
  }

  &.sm {
    padding: 0.5 * $app-base-padding $app-base-padding;;
  }

  .icon {
    font-size: 20px;
    color: var(--surfaceMediumEmphasis);
    cursor: pointer;
  }


  .section-title {
    color: var(--surfaceHighEmphasis);
    font-size: 1.125 * $app-base-font-size !important;
    font-weight: 600 !important;
    line-height: 1.375 * $app-base-font-size !important;

    &.lg {
      font-size: 1.875 * $app-base-font-size !important;
      line-height: 2 * $app-base-font-size !important;
    }
  }

  .section-subtitle {
    color: var(--surfaceMediumEmphasis);
    font-size: 0.75 * $app-base-font-size;

  }

  .section-content-scroll {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.label-value {
  display: flex;
  align-items: center;
  font-size: 0.75rem;

  &.column {
    align-items: flex-start;
  }

  &__label {
    color: var(--surfaceLightEmphasis);
    padding-right: 0.5 * $app-base-padding;
    white-space: nowrap;
  }

  &__value {
    font-weight: 600;
    align-items: center;
    display: flex;
    //color: var(--surfaceHighEmphasis);
  }
}

.status {
  display: flex;
  align-items: center;
  color: var(--surfaceDisabled);

  &:before {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    content: "";
    margin-right: 0.25 * $app-base-padding;
    display: inline-block;
    background-color: var(--surfaceDisabled);
  }

  &.warning {
    color: var(--warning);

    &:before {
      background-color: var(--warning);
    }
  }

  &.success {
    color: var(--surfaceOutline);

    &:before {
      background-color: var(--surfaceOutline);
    }
  }

  &.error {
    color: var(--error);

    &:before {
      background-color: var(--error);
    }
  }

  &.done {
    color: var(--light);

    &:before {
      background-color: var(--light);
    }
  }

  &.disabled {
    color: var(--surfaceDisabled);

    &:before {
      background-color: var(--surfaceDisabled);
    }
  }
}


.list-item-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.75 * $app-base-padding;
  cursor: pointer;

  &.active {
    background-color: var(--surfaceSubtleSelected);
  }

  .list-item-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $app-base-padding;
  }

  .list-item-box-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .list-item-box-content {
    display: flex;
    padding-bottom: $app-base-padding;
    justify-content: space-evenly;

    .list-item-box-content-left,
    .list-item-box-content-right {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
    }

    .list-item-box-content-left {
      align-items: flex-start;
      //flex: 1 1 100%;
      .label-value__label {
        //min-width: 100px;
      }
    }

    .list-item-box-content-right {
      align-items: flex-end;
    }
  }

  .list-item-box-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-top: 1px solid var(--surfaceOutline);
    padding-top: 0.5 * $app-base-padding;
  }
}

.base-grid-gap {
  grid-gap: $app-base-padding;
}

.simulation-creator-dialog {
  .k-dialog {
    background-color: var(--backgroud);
  }
}

.loader-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dictionary-box {
  .loader-block {
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 75px;
    width: 100%;
    height: 100%;
  }
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 0;
}

.error-msg {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: var(--error);
  font-size: $app-base-font-size;
  font-weight: 600;
  line-height: 1.25 * $app-base-font-size;

  border: 1px solid var(--error);
  border-left-width: 0;
  border-right-width: 0;
}

.negative {
  color: var(--red);
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--surfaceHover);
  z-index: 1000;
}

.details-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
  padding-top: $app-base-padding;

  .details-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding-right: 0.5 * $app-base-padding;
  }
}
