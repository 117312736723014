@use "./colors" as *;
@use "./variables" as *;

$data-grid-first-column-width: 365px;
$data-grid-column-width: 80px;

.data-grid {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-height: 100%;
  position: relative;


  .sticky-column {
    position: sticky;
    left: 0px;
    background-color: var(--surface);
    z-index: 1;
  }
  thead,
  .sticky-row,
  .sticky-row th {
    position: sticky;
    top: 0px;
    background-color: var(--surface);
    z-index: 1;
  }
  thead {
    th {
      background-color: var(--surface);
    }
  }

  .data-grid-header-template {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
  }

  .data-grid-row {
    display: flex;
    flex: 1;
    padding: 0.25 * $app-base-padding 0;
    align-items: center;
    min-width: calc(calc(var(--data-grid-row-col-num) + 2) * #{$data-grid-column-width} + #{$data-grid-first-column-width});

    &.disposal {
      background-color: var(--secondaryVariant);
      td {
        background-color: var(--secondaryVariant);
      }
    }

    &.summary {
      border-top: 1px solid var(--secondaryVariant);
    }

    .main-row-header-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;

    }
    .data-grid-row-header-name {
      font-weight: 500;
      font-size: 15px;
      line-height: 22.5px;
    }

    .indicator {
      font-size: 11px;
      width: 24px;
      cursor: pointer;
    }

    .data-grid-row-first-column,
    .data-grid-row-column {
      overflow: hidden;
      align-self: stretch;
    }

    .data-grid-row-first-column {
      display: flex;
      align-items: center;
      flex: 0 0 $data-grid-first-column-width;
      min-height: 24px;
      &.parameter {
        padding-left: calc(16px + 24px);
        align-items: center;
      }
      .k-textbox,
      .k-numerictextbox,
      .k-dropdownlist {
        width: 105px !important;
      }
      .k-numerictextbox {
        input {
          text-align: right;
        }
      }
    }
    .data-grid-row-column {
      padding: 0 2px;
      display: flex;
      flex: 0 0 $data-grid-column-width;
      justify-content: flex-end;
    }

    .data-value {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      &.total {
        font-weight: 700;
        color: var(--font) !important;
      }
      &.summary {
        color: var(--icon);
      }
      &.negative {
        color: var(--red) !important;
      }
    }
    .data-grid-text {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}
