@use 'sass:map';
@use 'sass:meta';
@use 'sass:string';

@function _get-type-value($config, $level, $name) {
  @return map.get(map.get($config, $level), $name);
}

@function font-size($config, $level) {
  @return _get-type-value($config, $level, font-size);
}

@function font-weight($config, $level) {
  @return _get-type-value($config, $level, font-weight);
}

@function line-height($config, $level) {
  @return _get-type-value($config, $level, line-height);
}

@function letter-spacing($config, $level) {
  @return _get-type-value($config, $level, letter-spacing);
}

@function font-family($config, $level: null) {
  $font-family: map.get($config, font-family);

  @if $level != null {
    $font-family: _get-type-value($config, $level, font-family);
  }

  // Guard against unquoting non-string values, because it's deprecated.
  @return if(meta.type-of($font-family) == string, string.unquote($font-family), $font-family);
}

@mixin typography-level($config, $level) {
  font-size: font-size($config, $level);
  font-weight: font-weight($config, $level);
  line-height: line-height($config, $level);
  font-family: font-family($config, $level);
  letter-spacing: letter-spacing($config, $level);
}


@function define-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: normal) {

  @return (
    font-size: $font-size,
    line-height: $line-height,
    font-weight: $font-weight,
    font-family: $font-family,
    letter-spacing: $letter-spacing
  );
}


@function define-typography-config(
  $font-family:'Poppins, "Helvetica Neue", sans-serif',
  $h1:     define-typography-level(30px, auto, bold),
  $h2:     define-typography-level(18px, auto, semibold),
  $h3:     define-typography-level(12px, auto, semibold),
  $sm-input-label:     define-typography-level(10px, 14px, medium),
  $sm-input-value:     define-typography-level(11px, 16px, semibold),
  $md-input-label:     define-typography-level(10px, auto, medium),
  $md-input-value:     define-typography-level(12px, auto, semibold),
  $input-label:     define-typography-level(11px, auto, medium),
  $input-value:     define-typography-level(14px, auto, semibold),
  $text-12:     define-typography-level(12px, 16px, 400),
  $text-14:     define-typography-level(14px, 18px, 400),
  $text-16:     define-typography-level(16px, 20px, 400),
) {

  // Declare an initial map with all of the levels.
  $config: (
    h1: $h1,
    h2: $h2,
    h3: $h3,
    sm-input-label: $sm-input-label,
    sm-input-value: $sm-input-value,
    md-input-label: $md-input-label,
    md-input-value: $md-input-value,
    input-label: $input-label,
    input-value: $input-value,
    text-12: $text-12,
    text-14: $text-14,
    text-16: $text-16,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map.get($level, font-family) == null {
      $new-level: map.merge($level, (font-family: $font-family));
      $config: map.merge($config, ($key: $new-level));
    }
  }

  // Add the base font family to the config.
  @return map.merge($config, (font-family: $font-family));
}

@mixin app-typography($selector: '.typography') {
  $config: define-typography-config();


  #{$selector} h1 {
    @include typography-level($config, h1);
    margin: 0;
  }
  #{$selector} h2 {
    @include typography-level($config, h2);
    margin: 0;
  }
  #{$selector} h3 {
    @include typography-level($config, h3);
    margin: 0;
  }

  #{$selector} .h1 {
    @include typography-level($config, h1);
  }

  #{$selector} .h2 {
    @include typography-level($config, h2);
  }

  #{$selector} .h3 {
    @include typography-level($config, h3);
  }

  #{$selector} .sm-input-label {
    @include typography-level($config, sm-input-label);
  }

  #{$selector} .sm-input-value {
    @include typography-level($config, sm-input-value);
  }

  #{$selector} .md-input-label {
    @include typography-level($config, md-input-label);
  }

  #{$selector} .md-input-value {
    @include typography-level($config, md-input-value);
  }

  #{$selector} .input-label {
    @include typography-level($config, input-label);
  }

  #{$selector} .input-value {
    @include typography-level($config, input-value);
  }
  #{$selector} .text-12 {
    @include typography-level($config, text-12);
  }
  #{$selector} .text-14 {
    @include typography-level($config, text-14);
  }
  #{$selector} .text-16 {
    @include typography-level($config, text-16);
  }
}
