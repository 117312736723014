@use "./colors" as *;
@use "./variables" as *;

.k-floating-label-container,
.k-input.k-textbox {
  width: 100% !important;
}

kendo-multicolumncombobox,
kendo-textarea,
.k-input,
.k-dropdownlist,
.k-combobox {
  background: var(--customBackground) !important;
  color: var(--font) !important;
  border-color: var(--customBackground) !important;
  min-height: 40px;

  &.k-disabled {
    opacity: 0.5 !important;
  }

  &.light {
    background-color: var(--surface) !important;
    border-color: var(--surfaceOutline) !important;
  }

  &.k-focus {
    border-color: var(--primary) !important;
  }

  &.k-input-sm,
  &.k-picker-sm,
  &.k-input-sm > .k-input,
  &.k-picker-sm > .k-dateinput {
    min-height: 32px;
    max-height: 32px;

    .k-input-inner {
      padding: 0.5 * $app-base-padding 0 0 0.5 * $app-base-padding !important;
      font-size: 0.75 * $app-base-font-size;
    }
  }

  &.xs {
    min-height: 22px;
    max-height: 22px;

    .k-input-inner {
      padding: 0 0 0 0.5 * $app-base-padding !important;
      font-size: 0.75 * $app-base-font-size;
    }

    .k-input-button {
      padding: 0.25 * $app-base-padding !important;
    }
  }

  .k-input-inner {
    padding: 0.75 * $app-base-padding $app-base-padding 0 0.5 * $app-base-padding !important;
    font-size: 0.8125rem;
  }

  .k-input-button {
    border-inline-start-width: 0px !important;
    padding: 0.25 * $app-base-padding !important;
  }

}

.k-dropdownlist {
  .k-i-caret-alt-down {
    font-family: 'ico' !important;
    font-size: 0.35 * $app-base-font-size;

    &:before {
      content: "\ea0b";
    }
  }
}

.k-floating-label-container {
  &:not(.k-empty) {
    .k-multiselect {
      min-height: 70px;
    }
  }
}

kendo-numerictextbox.k-input {
  &.percent {
    position: relative;
    display: inline-block;

    &:after {
      content: '%';
      position: absolute;
      top: 2px;
      right: .1em;
    }

    .k-input-inner {
      padding-right: 1.25 * $app-base-padding !important;
      text-align: right;
    }
  }
}

kendo-multicolumncombobox {
  display: flex;
}

.small-input {

  input {
    text-align: end;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    z-index: 0;
  }
}

textarea.k-input-inner {
  padding: 0.25 * $app-base-padding $app-base-padding 0 0.5 * $app-base-padding !important;
  margin-top: 12px;
}

.ng-invalid.ng-pristine.ng-untouched {
  .k-floating-label-container.k-invalid > .k-floating-label {
    color: rgba(0, 0, 0, 0.54);
  }
}

.k-input-solid, .k-picker-solid {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-width: 0;

  &:after {
    content: '';
    border-width: 2px;
    transition: none;
  }
}

.k-popup.k-dropdowngrid-popup {
    .k-table-row {
      .k-table-td {
        padding-block: 0.25rem;
        padding-inline: 1rem;
      }
    }
}

.k-form-field {
  width: 100%;
}