@font-face {
  font-family: "ico";
  src: url("fonts/ico.eot?t=1726846070090"); /* IE9*/
  src: url("fonts/ico.eot?t=1726846070090#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("fonts/ico.woff?t=1726846070090") format("woff"),
  url("fonts/ico.ttf?t=1726846070090") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url("fonts/ico.svg?t=1726846070090#ico") format("svg"); /* iOS 4.1- */
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ico' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ico-activate-circle:before { content: "\ea01"; }
.ico-add-circle:before { content: "\ea02"; }
.ico-add-square-1:before { content: "\ea03"; }
.ico-add-square:before { content: "\ea04"; }
.ico-alarm-bell-ring:before { content: "\ea05"; }
.ico-alarm-bell:before { content: "\ea06"; }
.ico-analysis:before { content: "\ea07"; }
.ico-analytics-graph-bar:before { content: "\ea08"; }
.ico-arow-left-circle:before { content: "\ea09"; }
.ico-arrow-down-1:before { content: "\ea0a"; }
.ico-bin-1:before { content: "\ea0b"; }
.ico-calendar:before { content: "\ea0c"; }
.ico-checkmark:before { content: "\ea0d"; }
.ico-class:before { content: "\ea0e"; }
.ico-close:before { content: "\ea0f"; }
.ico-collapse:before { content: "\ea10"; }
.ico-compare:before { content: "\ea11"; }
.ico-details-csv:before { content: "\ea12"; }
.ico-download:before { content: "\ea13"; }
.ico-duplicate:before { content: "\ea14"; }
.ico-evaluation-comparison:before { content: "\ea15"; }
.ico-evaluation-comparison4:before { content: "\ea16"; }
.ico-evaluation-comparison5:before { content: "\ea17"; }
.ico-evaluation:before { content: "\ea18"; }
.ico-evaluations-result:before { content: "\ea19"; }
.ico-expand:before { content: "\ea1a"; }
.ico-filter-1:before { content: "\ea1b"; }
.ico-flight-demand:before { content: "\ea1c"; }
.ico-folder-text:before { content: "\ea1d"; }
.ico-kpi:before { content: "\ea1e"; }
.ico-link:before { content: "\ea1f"; }
.ico-lock-1:before { content: "\ea20"; }
.ico-lock:before { content: "\ea21"; }
.ico-logout:before { content: "\ea22"; }
.ico-menu-1:before { content: "\ea23"; }
.ico-mlmodels:before { content: "\ea24"; }
.ico-open-in-new:before { content: "\ea25"; }
.ico-optimisation:before { content: "\ea26"; }
.ico-pencil:before { content: "\ea27"; }
.ico-plus:before { content: "\ea28"; }
.ico-profiles:before { content: "\ea29"; }
.ico-question-help-circle:before { content: "\ea2a"; }
.ico-refresh:before { content: "\ea2b"; }
.ico-remove-circle:before { content: "\ea2c"; }
.ico-row-align-bottom:before { content: "\ea2d"; }
.ico-row-align-top:before { content: "\ea2e"; }
.ico-save:before { content: "\ea2f"; }
.ico-school-book-1:before { content: "\ea30"; }
.ico-search:before { content: "\ea31"; }
.ico-ship:before { content: "\ea32"; }
.ico-sign-man:before { content: "\ea33"; }
.ico-single-neutral:before { content: "\ea34"; }
.ico-split-value:before { content: "\ea35"; }
.ico-summary-csv:before { content: "\ea36"; }
.ico-switch:before { content: "\ea37"; }
.ico-user:before { content: "\ea38"; }
.ico-users:before { content: "\ea39"; }
.ico-view:before { content: "\ea3a"; }
.ico-warning-circle:before { content: "\ea3b"; }
.ico-warning-triangle:before { content: "\ea3c"; }
.ico-wishlist:before { content: "\ea3d"; }
