@use "./colors" as *;
@use "./variables" as *;

.k-floating-label-container {
  padding-top: 0px !important;
  &:not(.k-empty) > .k-floating-label,
  &.k-focus > .k-floating-label {
    top: 0 !important;
    left: 0.5 * $app-base-padding !important;
    position: absolute;
  }
  &.k-empty:not(.k-focus) {
    .label-prefix {
      margin-left: $app-base-padding;
    }
  }
  &.k-empty:not(.k-focus) > .k-floating-label {
    top: calc(1px + 8px ) !important;
    left: calc( 8px + 1px );
    position: absolute;
  }
  &:not(.k-empty),
  &.k-focus {
    .k-textarea.k-input-md + .k-floating-label,
    .k-input-sm + .k-floating-label,
    .k-picker-sm + .k-floating-label {
      top: -5px !important;
      transform: scale(0.7) !important;
    }
  }

  &.k-input-sm,
  &.k-picker-sm {
    min-height: 32px;
    max-height: 32px;
  }
}
